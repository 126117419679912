/**
 * @file 组件 - 全局配置
 * @author hardylin <hardylin@tencent.com>
 */
import React from 'react'
import { URL } from '../../../../../src/scripts/constants/index'
import axios from '../../../../../src/scripts/request/axios'
import { ConfigContext } from './context.jsx'

export default class SadConfigContext extends React.Component {
  renderProvider(context) {
    // #region 修改全局配置
    const { development } = this.props
    axios.defaults.baseURL = `https://${development ? 'tmoss' : 'moss'}${
      URL.ENV
    }com`
    // #endregion

    return (
      <ConfigContext.Provider value={context}>
        {this.props.children}
      </ConfigContext.Provider>
    )
  }

  render() {
    return (
      <ConfigContext.Consumer>
        {renderProvider => this.renderProvider(renderProvider)}
      </ConfigContext.Consumer>
    )
  }
}
