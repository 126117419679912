import React from 'react'
import { Avatar, List, Icon, Popover, Tabs } from 'antd'
import { URL } from '../../../../../src/scripts/constants/index'
import { reqCDN } from '../../../../../src/scripts/request/index'
import '../../../../../src/components/nav/index.postcss'
// import nav from '../../../../../test/nav.json'

export default class SadNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nav: [],
    }
  }

  componentDidMount() {
    reqCDN.getNav().then(result => {
      this.setState({
        nav: result,
      })
    })
    // this.setState({
    //     nav,
    // })
  }

  render() {
    return (
      <Popover
        className='sad-nav'
        placement='bottomLeft'
        content={
          <Tabs className='sad-nav-tab' tabPosition='left'>
            {this.state.nav.map(item => (
              <Tabs.TabPane tab={item.name} key={item.id}>
                {item.children.map(item => (
                  <div key={item.id}>
                    <div className='title'>
                      <Avatar
                        className='avatar'
                        size='small'
                        shape='square'
                        src={
                          item.transform
                            ? item.icon.replace(/\.(oa|qq)\./, URL.ENV)
                            : item.icon
                        }
                      />
                      <h3>{item.name}</h3>
                    </div>
                    {item.desc && <div className='desc'>{item.desc}</div>}
                    <List
                      className='list'
                      grid={{ gutter: 16, column: 4 }}
                      dataSource={item.children}
                      renderItem={item => (
                        <List.Item>
                          <a
                            disabled={!item.link}
                            target={item.target}
                            href={
                              item.link
                                ? item.transform
                                  ? item.link.replace(/\.(oa|qq)\./, URL.ENV)
                                  : item.link
                                : ''
                            }>
                            {item.name} >
                          </a>
                        </List.Item>
                      )}></List>
                  </div>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
        }>
        <Icon type='appstore' /> 营销解决方案
      </Popover>
    )
  }
}
