import '../../../../../src/components/product/index.postcss'
import React from 'react'
import { Avatar, Select } from 'antd'
import { reqProduct } from '../../../../../src/scripts/request/index'

export default class SadProduct extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      product: {}, // 当前选中产品
      products: [],
      busy: false,
    }
  }

  onChange = value => {
    const product = this.state.products.find(item => item.id == value)
    this.setState({
      product,
    })
    this.props.onChange && this.props.onChange(product)
    this.props.updateDefaultValue && this.props.updateDefaultValue(product.id)
  }

  onSearch = value => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      this.getProductList(value)
    }, 500)
  }

  /**
   * 获取产品列表
   * @param {String} keyword - 搜索关键字
   */
  getProductList = keyword => {
    this.setState({
      busy: true,
    })
    reqProduct
      .getList({
        owned: 0,
        from: 0,
        size: this.props.number || 50,
        keyword,
      })
      .then(result => {
        const { products = [] } = result
        this.setState({
          products,
          busy: false,
        })
      })
      .catch(error => {
        this.setState({
          busy: false,
        })
      })
  }

  getProductDetail = () => {
    reqProduct.detail(this.props.defaultValue).then(result => {
      this.setState(state => ({
        product: result,
        products: [result],
      }))
      this.getProductList()
    })
  }

  componentDidMount() {
    if (this.props.defaultValue) {
      this.getProductDetail()
    } else {
      this.getProductList()
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.updateDefaultValue && this.props.defaultValue != prevProps.defaultValue) {
      this.getProductDetail()
    }
  }

  render() {
    return (
      <div
        className={`sad-product${this.props.theme == 'dark' ? ' dark' : ''} ${
          this.props.showProduct !== false ? 'show-product' : ''
        }`}>
        {this.props.showProduct !== false && (
          <div>
            <Avatar
              size={64}
              shape='square'
              src={this.state.product.logo_url}
            />
            <div className='name'>{this.state.product.name}</div>
          </div>
        )}
        <Select
          showSearch
          className='select'
          placeholder='搜索'
          filterOption={false}
          defaultActiveFirstOption={false}
          value={this.state.product.id}
          loading={this.state.busy}
          onChange={this.onChange}
          onSearch={this.onSearch}>
          {this.state.products.map(item => (
            <Select.Option key={item.id} value={item.id}>
              {item.name}（{item.identifiers.ams}）
            </Select.Option>
          ))}
        </Select>
      </div>
    )
  }
}
