/**
 * @file 全局配置 Context
 * @author hardylin <hardylin@tencent.com>
 */
import React from 'react'

const ConfigContextProps = {
  development: false,
}
const ConfigContext = React.createContext(ConfigContextProps)

export { ConfigContextProps, ConfigContext }
