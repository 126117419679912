import React from 'react'
import { Layout, Menu } from 'antd'
import SadNav from '../nav/index.jsx'
import '../../../../../src/components/header/index.postcss'

export default class SadHeader extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Layout.Header className={`sad-header ${this.props.className}`}>
        {this.props.logo}
        {this.props.isNeedIndex && (
          <Menu
            className='menu'
            mode='horizontal'
            theme='dark'
            defaultSelectedKeys={[location.href]}>
            <Menu.Item key='https://moss.oa.com/'>
              <a href='https://moss.oa.com/'>营销云</a>
            </Menu.Item>
          </Menu>
        )}
        <SadNav />
        {this.props.children}
      </Layout.Header>
    )
  }
}
